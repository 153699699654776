import { makeAutoObservable } from "mobx"

export default class UiStore {

	displayAds
	authEntryPoint
	selectedPlan
	selectedPlanBillingOption
	
	gate
	promo

	planFlowStep = null

	anonymousUploadComplete = false

	constructor() {
		this.displayAds = false;
		makeAutoObservable(this)
		this.resetPlanFlow()
	}

	setAnonymousUploadComplete(anonymousUploadComplete) {
		this.anonymousUploadComplete = anonymousUploadComplete
	}

	setDisplayAds(displayAds) {
		this.displayAds = displayAds ? 
			process.env.GATSBY_SHOW_ADS === 'TRUE':
			false;
	}

	setAuthEntryPoint(entryPoint) {
		this.authEntryPoint = entryPoint
	}

	setGate(gate) {
		console.log('setGate', gate)
		if (gate) {
			gate.preAuthLocation = gate.preAuthLocation || '/login'
		}
		this.gate = gate
	}

	setPromo(promo) {
		this.promo = promo
	}

	setPlanFlowStep(step) {
		this.planFlowStep = step
	}

	selectPlan(plan) {
		this.selectedPlan = plan
		this.setPlanFlowStep('selectedplan')
	}

	selectPlanBillingOption(option) {
		this.selectedPlanBillingOption = option
	}

	setBillingAddress(billingAddress) {
		this.billingAddress = billingAddress
	}

	resetPlanFlow() {
		this.planFlowStep = null
		this.selectedPlan = null
		this.planFlowStep = null
		this.selectedPlanBillingOption = 'm'
	}

	
}