
import tw, {css} from 'twin.macro'

export const linkStyle = css`
	& {
		${tw`transition-colors duration-200 no-underline border-b border-dotted text-blue border-blue border-opacity-50 cursor-pointer`}
	},
	&:hover {
		${tw`border-opacity-100`}
	}
	&.bare {
		${tw`border-0`}
	}
`
