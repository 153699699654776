import { isEqual } from 'lodash'
import jwt_decode from 'jwt-decode'

class User {
	
	constructor({username, email, authToken, email_verified}) {
		this.username = username
		this.email = email
		this.email_verified = email_verified
		this.authToken = authToken
		if (authToken) {
			try {
				this.jwtPayload = jwt_decode(authToken)
			}
			catch(err) {
				console.log(err)
			}
		}
	}

}

class CognitoUser extends User {

	constructor(cognitoUser) {
		super({
			username: cognitoUser.username,
			email: cognitoUser.signInUserSession.idToken.payload.email,
			authToken: cognitoUser.signInUserSession.idToken.jwtToken,
			email_verified: cognitoUser.attributes.email_verified
		})
		this.cognitoUser = cognitoUser
	}

	equals(other) {
		return isEqual(this.cognitoUser.attributes, other.cognitoUser.attributes) && 
		Boolean(this.cognitoUser.signInUserSession) &&
		isEqual(this.cognitoUser.signInUserSession.idToken, other.cognitoUser.signInUserSession.idToken)
	}

}

class AnonymousUser extends User {

	constructor(jwt) {
		super({
			authToken: jwt
		})
	}

}

export { User, CognitoUser, AnonymousUser }