/* eslint-disable */

const awsmobile = {
	"aws_project_region": process.env.GAYSBY_AWS_PROJECT_REGION || 'us-east-1',
	"aws_cognito_region": process.env.GAYSBY_AWS_COGNITO_REGION || 'us-east-1',
	"aws_user_pools_id": process.env.GATSBY_AWS_USER_POOLS_ID,
	"aws_user_pools_web_client_id": process.env.GATSBY_AWS_USER_POOLS_WEB_CLIENT_ID,
	"oauth": {}
};

export default awsmobile;
