import React, { useState, useEffect, useCallback, useContext, useMemo, createContext } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit'
import { useUser } from './useUser'
import { merge } from 'lodash'

export const ApiContext = createContext(null);

export const ApiProvider = ({ children }) => {

	const http = rateLimit(axios.create(), { maxRPS: 4})

	const values = useMemo(() => ({ http }), [http]);

	return <ApiContext.Provider value={values}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
	
	const { http } = useContext(ApiContext)
	const { refreshAuthenticatedUser, setAnonymousUserToken } = useUser();

	const callApi = useCallback( (params) => {
		
		const path = [process.env.GATSBY_API_URL, params.namespace, params.id].filter(Boolean).join("/");

		const config = merge({
			method: 'get',
			url: path,
			headers: {
				"Content-type": "application/json",
			},
			data: params.payload
		}, params)

		/*
		if (!anonymous) {
			const user = await refreshAuthenticatedUser();
			if (user) {
				config.headers.Authorization = `Bearer ${user.authToken}`
			}
		}
		*/

		const doRequest = async(config) => {
			const user = await refreshAuthenticatedUser();
			if (user) {
				config.headers.Authorization = `Bearer ${user.authToken}`
			}
			return http(config)
			.then(result => {
				if (setAnonymousUserToken) {
					setAnonymousUserToken(result.headers['x-file-io-anonymous-access-token'])
				}
				return result
			})
		}
	
		return doRequest(config)

	}, [refreshAuthenticatedUser, http, setAnonymousUserToken])

	const callApiAbortable = useCallback( (params) => {

		const abortController = new AbortController()

		return {
			abortController,
			request: callApi({
				...params,
				signal: abortController.signal
			})
		}
		// pass abortController to api?

	}, [callApi])
	return { callApi, callApiAbortable };
};