/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React, { useEffect, useContext, useState } from "react"
import Amplify from "aws-amplify"
import awsConfig from "./src/aws-exports-env"
import { UserProvider } from "./src/hooks/useUser"
import { MeProvider } from "./src/hooks/useMe"
import { ApiProvider } from './src/hooks/useApi'
import UiStoreContext from "./src/contexts/uiStoreContext"
import UiStore from './src/stores/uiStore'
import './src/styles/styles.css'
import tw, {GlobalStyles} from "twin.macro"
import { ThemeProvider } from './src/hooks/useTheme'
import { useUser } from './src/hooks/useUser'
import { CognitoUser } from './src/classes/user'
import { navigate, Link } from "gatsby"
import { observer } from 'mobx-react-lite'
import SiteStylesWrapper from "./src/styles/siteStylesWrapper"

Amplify.configure(awsConfig)

const Controller = observer(({element}) => {

	const uiStore = useContext(UiStoreContext)
	const { user } = useUser()
	const [loggedIn, setLoggedIn] = useState(undefined)

	useEffect(() => {

		if (typeof user === 'undefined') {
			return
		}

		if(user instanceof CognitoUser) {
			setLoggedIn(true)
			if (uiStore.gate) {
				// console.log('got Cognito user, navigating to:' + uiStore.gate.postAuthLocation)
				navigate(uiStore.gate.postAuthLocation)
				uiStore.setGate(null)
			}
		}
		else {
			if(loggedIn) {
				// console.log('no longer logged in')
				uiStore.resetPlanFlow()
				uiStore.setGate(null)
				setLoggedIn(false)
				navigate("/")
			}
			else {
				// console.log('not yet logged in')
				if (uiStore.gate) {
					// console.log('navigating to pre-auth gate location')
					navigate(uiStore.gate.preAuthLocation, uiStore.gate.preAuthParams)
				}
			}
		}


	}, [user, uiStore.gate])

	return <>
		{/* <div tw="bg-red absolute z-50">
			<div><pre><code>{JSON.stringify(uiStore, null, 2)}</code></pre></div>
		</div> */}
		{element}
	</>
	
})

export const wrapRootElement = ( {element} ) => {
	const uiStore = new UiStore()
	return (
		<div id="root-element" tw="bg-transparent font-sans h-full">
			<SiteStylesWrapper>
				<GlobalStyles/>
				<ThemeProvider>
					<UiStoreContext.Provider value={uiStore}>
						<UserProvider>
							<ApiProvider>
								<MeProvider>
									<Controller element={element}/>
								</MeProvider>
							</ApiProvider>
						</UserProvider>
					</UiStoreContext.Provider>
				</ThemeProvider>
			</SiteStylesWrapper>
		</div>
	)
}