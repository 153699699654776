import React, { useCallback, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { CognitoUser, AnonymousUser, User } from '../classes/user'
import useNoRenderRef from '../hooks/useNoRenderRef'

export const UserContext = React.createContext()

const NULL_USER = new User({})

export const UserProvider = ({ children }) => {

	const [user, setUser] = useState(undefined)
	const userRef = useNoRenderRef(user)

	const [anonymousUser, setAnonymousUser] = useState()
	const anonymousUserRef = useNoRenderRef(anonymousUser)

	const refreshAuthenticatedUser = useCallback( (params) => {
		// console.log("refreshAuthenticatedUser: bypassCache=" + (params && params.bypassCache))
		return Auth.currentAuthenticatedUser(params)
		.then(cognitoUserRaw => {
			const newCognitoUser = new CognitoUser(cognitoUserRaw)
			if (
				userRef.current instanceof CognitoUser && 
				userRef.current.equals(newCognitoUser) && 
				!(params && params.bypassCache)
			) {
				return userRef.current
			}
			setUser(newCognitoUser);
			return newCognitoUser;
		})
		.catch((err) => {
			setUser(anonymousUserRef.current || NULL_USER);
			return anonymousUserRef.current;
		})
	}, [userRef, anonymousUserRef]);

	const login = useCallback( (usernameOrEmail, password) => {
		return Auth.signIn(usernameOrEmail, password)
		.then(cognitoUserRaw => {
			const newCognitoUser = new CognitoUser(cognitoUserRaw)
			if (
				userRef.current instanceof CognitoUser && 
				userRef.current.equals(newCognitoUser)
			) {
				return userRef.current
			}
			setUser(newCognitoUser)
			return newCognitoUser
		})
		.catch((err) => {
			if (err.code === 'UserNotFoundException') {
				err.message = 'Invalid username or password';
			}

			// ... (other checks)

			throw err;
		})
	}, [userRef])

	const setAnonymousUserToken = useCallback( (token) => {
		if (anonymousUserRef.current || !token) {
			return
		}
		setAnonymousUser(new AnonymousUser(token))
	}, [anonymousUserRef])

	const clearAnonymousUserToken = useCallback( () => {
		setAnonymousUser(null)
	}, [anonymousUserRef])

	useEffect( () => {
		refreshAuthenticatedUser()
	}, [refreshAuthenticatedUser])

	useEffect(() => {
		refreshAuthenticatedUser()
	}, [anonymousUser])

	const values = React.useMemo( () => {

		const logout = () => {
			return Auth.signOut().then(data => {
				return refreshAuthenticatedUser()
				//setUser(anonymousUser);
				//return data;
			})
		}

		const signup = (name, email, password, optinUpdates, optinMarketing) => {
			return Auth.signUp({
				username: email,
				password,
				attributes: {
					email,
					name: name,
					'custom:optin_updates': optinUpdates ? '1' : '0',
					'custom:optin_marketing': optinMarketing ? '1' : '0'
				}
			})
			.catch(err => {
				console.log(err)
				throw err
			})
		};

		const changePassword = async (currentPassword, newPassword) => {
			return Auth.currentAuthenticatedUser()
				.then(user => {
					return Auth.changePassword(user, currentPassword, newPassword);
				})
		}

		return { user, signup, login, logout, changePassword, refreshAuthenticatedUser, setAnonymousUserToken, clearAnonymousUserToken }

	}, [user, setUser, refreshAuthenticatedUser, login, setAnonymousUserToken, clearAnonymousUserToken]);

	return <UserContext.Provider value={values}>{children}</UserContext.Provider>;

};

export const useUser = () => {
	const context = React.useContext(UserContext);

	if (context === undefined) {
		throw new Error('`useUser` hook must be used within a `UserProvider` component');
	}
	return context;
};