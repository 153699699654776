import React, { useState, useEffect, useCallback, useContext, useMemo, createContext } from "react"
import tw from "twin.macro"
import mq from '../styles/media-queries'
import merge from 'lodash.merge'

export const ThemeContext = createContext()

const DEFAULT_THEME = {
	nav: {
		[mq.md] : {
			...tw`bg-gradient-to-r from-blue to-purple`,
			a : {
				...tw`text-white border-white`
			}
		}
	}
}

const TRANSPARENT_THEME = {
	nav: {
		...tw`bg-transparent`
	}
}

export const ThemeProvider = ({children}) => {
	
	const [theme, setThemeInternal] = useState(TRANSPARENT_THEME)

	const setTheme = useCallback((newTheme) => {

		const mergedTheme = merge({}, DEFAULT_THEME, newTheme)
		setThemeInternal(mergedTheme)

	}, [])

	const setDefaultTheme = useCallback(() => { 
		setThemeInternal(DEFAULT_THEME) 
	}, [])

	// const values = useMemo(() => ({theme, setTheme, setDefaultTheme}), [theme, setDefaultTheme])
	const values = {theme, setTheme, setDefaultTheme}

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>

}

export const useTheme = () => {

	return  useContext(ThemeContext)

}