import React from "react"
import tw from "twin.macro"
import { linkStyle } from './common-styles'

const SiteStylesWrapper = ({children}) => {
	return <div tw="bg-transparent font-sans h-full" css={{
		'h1' : {
			...tw`text-3xl mb-8 font-extrabold`
		},
		'h2': {
			...tw`text-xl mb-6 font-bold`
		},
		'p' : {
			...tw`mb-2`
		},
		'a' : {
			...linkStyle
		},
		'select, input, textarea' : {
			...tw`block border border-gray-400 w-full p-2 rounded-md mb-4 bg-transparent`
		},
		'label' : {
			...tw`w-full pl-1 pb-1`
		},
		'.error' : {
			...tw`-mt-2 pl-1 mb-4 w-full text-red`
		}
	  }}>{children}</div>
}

export default SiteStylesWrapper