import React, { useState, useCallback } from 'react';
import { useApi } from './useApi'
import { useUser } from './useUser';

export const MeContext = React.createContext({
	me: null,
	meChecked: null,
	refreshMe: null
});

export const MeProvider = ({ children }) => {

	const [me, setMe] = useState()
	const [meChecked, setMeChecked] = useState(false)

	const { refreshAuthenticatedUser } = useUser()
	const { callApi } = useApi()

	React.useEffect(() => {
		setMeChecked(typeof me !== 'undefined')
	}, [me])

	const refreshMe = useCallback(async () => {
		return refreshAuthenticatedUser()
			.then(user => {
				if (user && user.email) {
					return callApi({
						method: 'get',
						namespace: 'me'
					})
						.then((result) => {
							const freshMe = result.data
							setMe(freshMe)
						})
						.catch((err) => {
							setMe(null)
						})
				}
				else {
					setMe(null)
				}
			})
	}, [callApi])

	React.useEffect(() => {
		refreshMe()
	}, [refreshMe]);

	const values = React.useMemo(() => ({ me, meChecked, refreshMe }), [me, meChecked, refreshMe]);

	return <MeContext.Provider value={values}>{children}</MeContext.Provider>;
};

export const useMe = () => {
	const context = React.useContext(MeContext);

	if (context === undefined) {
		throw new Error('`useMe` hook must be used within a `MeProvider` component');
	}
	return context;
};